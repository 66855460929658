import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import {
    PageTitle,
    Panel,
    Button,
    Tabordion,
    useAddToast,
    Modal,
} from "@cortexglobal/rla-components";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { CubeLoader, ButtonDropdown } from "@cortexglobal/lens-components";

import CompanySales from "./CompanySales";
import queryString from "querystring";
import CompanyContacts from "./CompanyContacts";
import CompanyRewardOrders from "./CompanyRewardOrders";
import CompanyLinkedAccounts from "./CompanyLinkedAccounts";
import CompanyCommunications from "./CompanyCommunications";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IndividualManualMatch from "../customer-mapping/IndividualManualMatch";
import ManualMerge from "./ManualMerge";
import CompanyMergeLog from "./CompanyMergeLog";
import CompanyData from "./CompanyData";
import CompanyPoints from "./CompanyPoints";
import { Row, Column } from "@cortexglobal/rla-components";

const PanelSection = styled.div`
    padding: 2em;
`;

const CompanyContainer = styled.div`
    display: flex;
`;

const CompanyActions = styled.div``;

const ViewCompany = ({ match }) => {
    const ViewCompanyTabordion = React.createRef();

    const history = useHistory();
    const addToast = useAddToast();

    const [loading, setLoading] = useState(true);
    const [companyData, setCompanyData] = useState();

    const {
        currentTab,
        salesPerPage,
        salesPage,
        salesOrderBy,
        salesOrderDir,
        contactsPerPage,
        contactsPage,
        communicationsPage,
        communicationsPerPage,
        mergeLogPage,
        mergeLogPerPage,
    } = queryString.parse(useLocation().search.substring(1));

    const [currentTabValue, setCurrentTabValue] = useState(currentTab || 0);

    const [manualMatch, setManualMatch] = useState(null);
    const [manualMerge, setManualMerge] = useState(null);

    const [salesData, setSalesData] = useState([]);
    const [salesMeta, setSalesMeta] = useState();
    const [salesLoading, setSalesLoading] = useState(false);
    const [salesPerPageValue, setSalesPerPageValue] = useState(
        salesPerPage || 20
    );
    const [selectedSalesPageValue, setSelectedSalesPageValue] = useState(
        salesPage === undefined ? 1 : salesPage
    );
    const [salesOrderByValue, setSalesOrderByValue] = useState(salesOrderBy);
    const [salesOrderDirectionValue, setSalesOrderDirectionValue] =
        useState(salesOrderDir);

    const [communicationData, setCommunicationData] = useState([]);
    const [communicationMeta, setCommunicationMeta] = useState([]);
    const [communicationsLoading, setCommunicationsLoading] = useState(false);
    const [communicationsPerPageValue, setCommunicationsPerPageValue] =
        useState(communicationsPerPage || 20);
    const [selectedCommunicationsPageValue, setSelectedCommunicationPageValue] =
        useState(communicationsPage === undefined ? 1 : communicationsPage);

    const [contactsData, setContactsData] = useState([]);
    const [contactsMeta, setContactsMeta] = useState();
    const [contactsLoading, setContactsLoading] = useState(false);
    const [contactsPerPageValue, setContactsPerPageValue] = useState(
        contactsPerPage || 20
    );
    const [selectedContactsPageValue, setSelectedContactsPageValue] = useState(
        contactsPage === undefined ? 1 : contactsPage
    );

    const [mergeLogData, setMergeLogData] = useState([]);
    const [mergeLogDataMeta, setMergeLogMeta] = useState();
    const [mergeLogDataLoading, setMergeLogLoading] = useState(false);
    const [mergeLogPerPageValue, setNergeLogPerPageValue] = useState(
        mergeLogPerPage || 20
    );
    const [selectedMergeLogPageValue, setSelectedMergeLogPageValue] = useState(
        mergeLogPage === undefined ? 1 : mergeLogPage
    );

    const [rewardOrdersData, setRewardOrdersData] = useState([]);
    const [rewardOrdersMeta, setRewardOrdersMeta] = useState();
    const [rewardOrdersLoading, setRewardOrdersLoading] = useState(false);
    const [rewardOrdersPerPageValue, setRewardOrdersPerPageValue] = useState(
        contactsPerPage || 20
    );
    const [selectedRewardOrdersPageValue, setSelectedRewardOrdersPageValue] =
        useState(contactsPage === undefined ? 1 : contactsPage);

    const [pointsData, setPointsData] = useState([]);
    const [pointsMeta, setPointsMeta] = useState();
    const [pointsLoading, setPointsLoading] = useState(false);
    const [pointsPerPageValue, setPointsPerPageValue] = useState(
        contactsPerPage || 20
    );

    const [selectedPointsPageValue, setSelectedPointsPageValue] = useState(
        contactsPage === undefined ? 1 : contactsPage
    );

    const [linkedAccountsData, setLinkedAccountsData] = useState([]);
    const [linkedAccountsMeta, setLinkedAccountsMeta] = useState();
    const [linkedAccountsLoading, setLinkedAccountsLoading] = useState(false);
    const [linkedAccountsPerPageValue, setLinkedAccountsPerPageValue] =
        useState(contactsPerPage || 20);
    const [
        selectedLinkedAccountsPageValue,
        setSelectedLinkedAccountsPageValue,
    ] = useState(contactsPage === undefined ? 1 : contactsPage);

    const getSales = (newData) => {
        setSalesLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}/invoices`, {
                params: {
                    page: selectedSalesPageValue,
                    per_page: salesPerPageValue ? salesPerPageValue : 20,
                    order_by: salesOrderByValue,
                    order_dir: salesOrderDirectionValue,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setSalesData(data.data);
                setSalesMeta(data.meta);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting sales data.",
                    showFor: 5000,
                });
            })
            .finally(() => {
                setSalesLoading(false);
            });
    };

    const getMergeLog = (newData) => {
        setCommunicationsLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}/merge`, {
                params: {
                    page: selectedMergeLogPageValue,
                    per_page: mergeLogPerPageValue ? mergeLogPerPageValue : 20,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setMergeLogData(data.data);
                setMergeLogMeta(data.meta);
                setMergeLogLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting communication data.",
                    showFor: 5000,
                });
            })
            .finally(() => {
                setCommunicationsLoading(false);
            });
    };

    const getCommunications = (newData) => {
        setCommunicationsLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}/communications`, {
                params: {
                    page: selectedCommunicationsPageValue,
                    per_page: communicationsPerPageValue
                        ? communicationsPerPageValue
                        : 20,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setCommunicationData(data.data);
                setCommunicationMeta(data.meta);
                setCommunicationsLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting communication data.",
                    showFor: 5000,
                });
            })
            .finally(() => {
                setCommunicationsLoading(false);
            });
    };

    const getContacts = (newData) => {
        setContactsLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}/contacts`, {
                params: {
                    page: selectedContactsPageValue,
                    per_page: contactsPerPageValue ? contactsPerPageValue : 20,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setContactsData(data.data);
                setContactsMeta(data.meta);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting contacts.",
                    showFor: 5000,
                });
            })
            .finally(() => {
                setContactsLoading(false);
            });
    };

    const getRewardOrders = (newData) => {
        setRewardOrdersLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}/reward-orders`, {
                params: {
                    page: selectedRewardOrdersPageValue,
                    per_page: rewardOrdersPerPageValue
                        ? rewardOrdersPerPageValue
                        : 20,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setRewardOrdersData(data.data);
                setRewardOrdersMeta(data.meta);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting reward orders.",
                    showFor: 5000,
                });
            })
            .finally(() => {
                setRewardOrdersLoading(false);
            });
    };

    const getPointsData = (newData) => {
        setPointsLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}/points`, {
                params: {
                    page: selectedPointsPageValue,
                    per_page: pointsPerPageValue ? pointsPerPageValue : 20,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setPointsData(data.data);
                setPointsMeta(data.meta);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting points.",
                    showFor: 5000,
                });
            })
            .finally(() => {
                setPointsLoading(false);
            });
    };

    const getLinkedAccounts = (newData) => {
        setLinkedAccountsLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}/accounts`, {
                params: {
                    page: selectedLinkedAccountsPageValue,
                    per_page: linkedAccountsPerPageValue
                        ? linkedAccountsPerPageValue
                        : 20,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setLinkedAccountsMeta(data.meta);
                setLinkedAccountsData(data.data);
                setLinkedAccountsLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting linked accounts.",
                    showFor: 5000,
                });
                setLoading(false);
            })
            .finally(() => {
                setLinkedAccountsLoading(false);
            });
    };

    const getCompany = () => {
        setLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}`)
            .then(({ data }) => {
                setCompanyData(data.data);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting the company.",
                    showFor: 5000,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getCompany();
        getSales();
        getCommunications();
        getContacts();
        getMergeLog();
        getRewardOrders();
        getLinkedAccounts();
        getPointsData();
    }, []);

    const setParams = (newData, changeHistory = true) => {
        const data = {
            current_tab: currentTabValue,
            sales_per_page: salesPerPageValue,
            sales_page: selectedSalesPageValue,
            sales_order_by: salesOrderByValue,
            sales_order_dir: salesOrderDirectionValue,
            contacts_per_page: contactsPerPageValue,
            contacts_page: selectedContactsPageValue,
            reward_orders_per_page: rewardOrdersPerPageValue,
            reward_orders_page: selectedRewardOrdersPageValue,
            linked_accounts_per_page: linkedAccountsPerPageValue,
            linked_accounts_page: selectedLinkedAccountsPageValue,
            communications_per_page: communicationsPerPageValue,
            communications_page: selectedCommunicationsPageValue,
            ...newData,
        };

        if (changeHistory) {
            history.push({
                search: `?currentTab=${data.current_tab}&salesPage=${data.sales_page}&salesPerPage=${data.sales_per_page}&salesOrderBy=${data.sales_order_by}&salesOrderDir=${data.sales_order_dir}&contactsPage=${data.contacts_page}&contactsPerPage=${data.contacts_per_page}&rewardOrdersPage=${data.reward_orders_page}&rewardOrdersPerPage=${data.reward_orders_per_page}&linkedAccountsPage=${data.linked_accounts_page}&linkedAccountsPerPage=${data.linked_accounts_per_page}&communicationsPage=${data.communications_page}&communicationsPerPage=${data.communications_per_page}`,
            });
        }
    };

    const handleTabChange = (current) => {
        setCurrentTabValue(current);

        setParams(
            {
                current_tab: current,
            },
            false
        );
    };

    const handleSalesPerPage = ({ value }) => {
        if (value) {
            setSalesPerPageValue(value);

            setParams({
                sales_per_page: value,
                sales_page: 1,
            });
            getSales({
                per_page: value,
                page: 1,
            });
        }
    };

    const handleSalesPageChange = ({ selected }) => {
        setSelectedSalesPageValue(selected + 1);

        setParams({
            sales_page: selected + 1,
        });
        getSales({
            page: selected + 1,
        });
    };

    const handleSalesSortChange = (value, sortByValue) => {
        setSalesOrderDirectionValue(value);
        setSalesOrderByValue(sortByValue);

        setParams({
            sales_order_by: sortByValue,
            sales_order_dir: value,
            page: 1,
        });
        getSales({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
    };

    const handleContactsPerPage = ({ value }) => {
        if (value) {
            setContactsPerPageValue(value);

            setParams({
                contacts_per_page: value,
                contacts_page: 1,
            });
            getContacts({
                per_page: value,
                page: 1,
            });
        }
    };

    const handleContactsPageChange = ({ selected }) => {
        setSelectedContactsPageValue(selected + 1);

        setParams({
            contacts_page: selected + 1,
        });
        getContacts({
            page: selected + 1,
        });
    };

    const handleRewardOrdersPerPage = ({ value }) => {
        if (value) {
            setRewardOrdersPerPageValue(value);

            setParams({
                contacts_per_page: value,
                contacts_page: 1,
            });
            getRewardOrders({
                per_page: value,
                page: 1,
            });
        }
    };

    const handleRewardOrdersPageChange = ({ selected }) => {
        setSelectedRewardOrdersPageValue(selected + 1);

        setParams({
            contacts_page: selected + 1,
        });
        getRewardOrders({
            page: selected + 1,
        });
    };

    const handlePointsPerPage = ({ value }) => {
        if (value) {
            setPointsPerPageValue(value);

            setParams({
                contacts_per_page: value,
                contacts_page: 1,
            });
            getPointsData({
                per_page: value,
                page: 1,
            });
        }
    };

    const handlePointsPageChange = ({ selected }) => {
        setSelectedPointsPageValue(selected + 1);

        setParams({
            contacts_page: selected + 1,
        });
        getPointsData({
            page: selected + 1,
        });
    };

    const handleAcceptCustomerMerge = (customerToMergeWith) => {
        axios
            .post(
                `/api/v1/customers/${match.params.id}/merge`,
                customerToMergeWith
            )
            .then(({ data }) => {
                setManualMerge(null);
                addToast({
                    type: "success",
                    content: "Customer successfully mapped.",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error accepting customer mapping.",
                    showFor: 5000,
                });
                setLoading(false);
            })
            .finally(() => {});
    };

    const markCustomerAsDeadOrResurrect = (status, type) => {
        console.log(status, type);
        axios
            .get(`/api/v1/customers/${match.params.id}/mark`, {
                params: {
                    status: status,
                    type: type,
                },
            })
            .then(({ data }) => {
                addToast({
                    type: "success",
                    content: `Customer successfully marked ${status}.`,
                    showFor: 5000,
                });
                getCompany();
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error marking customer.",
                    showFor: 5000,
                });
                setLoading(false);
            })
            .finally(() => {});
    };

    const recalcPerks = () => {
        axios
            .post(`/api/v1/customers/${match.params.id}/recalculate-perks`)
            .then(({ data }) => {
                addToast({
                    type: "success",
                    content: `Customer PERKS points re-calculation has been started.`,
                    showFor: 5000,
                });
                getCompany();
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error recalculating PERKS points.",
                    showFor: 5000,
                });
                setLoading(false);
            })
            .finally(() => {});
    };

    const markCustomerAsActivatedOrNot = (status) => {
        console.log(status);
        axios
            .get(`/api/v1/customers/${match.params.id}/mark-activation`, {
                params: {
                    status: status,
                },
            })
            .then(({ data }) => {
                addToast({
                    type: "success",
                    content: `Customer successfully ${status}.`,
                    showFor: 5000,
                });
                getCompany();
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content:
                        "There was an error setting the activation status of this customer.",
                    showFor: 5000,
                });
                setLoading(false);
            })
            .finally(() => {});
    };

    const handleAcceptCustomerMapping = (customerMapping) => {
        const customerMap = customerMapping.map((customerMap) => {
            const { id, ...newMap } = customerMap;
            return {
                ...newMap,
                decision: "accepted",
            };
        });

        axios
            .put(`/api/v1/customers/mapping-as-individual`, {
                mappings: customerMap,
            })
            .then(({ data }) => {
                setManualMatch(null);
                addToast({
                    type: "success",
                    content: "Customer successfully mapped.",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error accepting customer mapping.",
                    showFor: 5000,
                });
                setLoading(false);
            })
            .finally(() => {});
    };

    const handleLinkedAccountsPerPage = ({ value }) => {
        if (value) {
            setLinkedAccountsPerPageValue(value);

            setParams({
                contacts_per_page: value,
                contacts_page: 1,
            });
            getLinkedAccounts({
                per_page: value,
                page: 1,
            });
        }
    };

    const handleCommunicationsPerPage = ({ value }) => {
        if (value) {
            setLinkedAccountsPerPageValue(value);

            setParams({
                contacts_per_page: value,
                contacts_page: 1,
            });
            getLinkedAccounts({
                per_page: value,
                page: 1,
            });
        }
    };

    const handleLinkedAccountsPageChange = ({ selected }) => {
        setSelectedLinkedAccountsPageValue(selected + 1);

        setParams({
            contacts_page: selected + 1,
        });
        getLinkedAccounts({
            page: selected + 1,
        });
    };

    const handleCommunicationsPageChange = ({ selected }) => {
        setSelectedCommunicationPageValue(selected + 1);

        setParams({
            contacts_page: selected + 1,
        });
        getCommunications({
            page: selected + 1,
        });
    };

    const generateActions = () => {
        let actions = [
            {
                name: "Edit Company",
                onClick: () =>
                    history.push("/companies/edit/" + match.params.id),
            },
            {
                name: "Manual Match",
                onClick: () => setManualMatch(companyData),
            },
            {
                name: "Merge",
                onClick: () => setManualMerge(companyData),
            },
        ];

        if (companyData && companyData.deleted_at) {
            actions.push({
                name: "Restore From Deleted",
                onClick: () =>
                    markCustomerAsDeadOrResurrect("restored", "deleted"),
            });
        }

        if (companyData && companyData.marked_as_dead) {
            actions.push({
                name: "Restore From Dead",
                onClick: () =>
                    markCustomerAsDeadOrResurrect("restored", "dead"),
            });
        }

        if (companyData && !companyData.deleted_at) {
            actions.push({
                name: "Mark As Deleted",
                onClick: () => markCustomerAsDeadOrResurrect("dead", "deleted"),
            });
        }

        if (companyData && !companyData.marked_as_dead) {
            actions.push({
                name: "Mark As Dead",
                onClick: () => markCustomerAsDeadOrResurrect("dead", "dead"),
            });
        }

        if (
            companyData &&
            companyData.type == "Authorised Repairer" &&
            companyData.tier == 2 &&
            companyData.activated_at
        ) {
            actions.push({
                name: "De-activate For PERKS",
                onClick: () => markCustomerAsActivatedOrNot("not activated"),
            });
        }

        if (
            companyData &&
            companyData.type == "Authorised Repairer" &&
            companyData.tier == 2 &&
            !companyData.activated_at
        ) {
            actions.push({
                name: "Activate For PERKS",
                onClick: () => markCustomerAsActivatedOrNot("activated"),
            });
        }

        if (companyData && companyData.tier == 2 && companyData.activated_at) {
            actions.push({
                name: "Recalculate PERKS Points",
                onClick: () => recalcPerks(),
            });
        }

        return actions;
    };

    return (
        <>
            <PageTitle
                title={
                    companyData
                        ? companyData.trade_team_customer
                        : `Loading Company`
                }
                expanded
            >
                <Row>
                    <Column>
                        <h3>
                            {" "}
                            {companyData
                                ? `DISTRIGO NUMBER: ${companyData.trade_team_id} `
                                : null}
                        </h3>
                    </Column>
                </Row>

                <Link to="/companies" style={{ marginRight: "1rem" }}>
                    <Button>
                        <FontAwesomeIcon icon={faCaretLeft} /> Back to Companies
                    </Button>
                </Link>
                <ButtonDropdown
                    hidePrimaryAction={false}
                    name="primary"
                    disabled={!!loading}
                    actions={generateActions()}
                >
                    Actions
                </ButtonDropdown>
            </PageTitle>

            <Modal
                visible={!!manualMerge}
                onClose={() => setManualMerge(null)}
                maxWidth="90%"
            >
                <ManualMerge
                    companyDetails={companyData}
                    onClose={() => setManualMerge(null)}
                    handleAcceptCustomerMerge={handleAcceptCustomerMerge}
                    loading={!!loading}
                    customerTypes={[]}
                    canCreateNewCustomer={false}
                />
            </Modal>

            <Modal
                visible={!!manualMatch}
                onClose={() => setManualMatch(null)}
                maxWidth="90%"
            >
                <IndividualManualMatch
                    customerMap={{ mappable_entry: companyData }}
                    onClose={() => setManualMatch(null)}
                    handleAcceptCustomerMapping={handleAcceptCustomerMapping}
                    loading={!!loading}
                    customerTypes={[]}
                    canCreateNewCustomer={false}
                />
            </Modal>

            {(loading ||
                salesLoading ||
                contactsLoading ||
                rewardOrdersLoading ||
                linkedAccountsLoading) && <CubeLoader />}

            {!(
                loading ||
                salesLoading ||
                contactsLoading ||
                rewardOrdersLoading ||
                linkedAccountsLoading
            ) && (
                <CompanyContainer>
                    <CompanyActions>
                        <Panel
                            style={{
                                padding: "0 0 1rem 0",
                                width: "100%",
                            }}
                        >
                            <Tabordion
                                current={parseInt(currentTabValue)}
                                ref={ViewCompanyTabordion}
                                onClick={() => {
                                    // This is a little hacky but it'll let us log which tab is selected for when the page refreshes. Ideally Tabordion would have a callback or hook we can use.
                                    handleTabChange(
                                        ViewCompanyTabordion.current.state
                                            .current
                                    );
                                }}
                            >
                                {companyData && (
                                    <PanelSection
                                        heading="Company Details"
                                        style={{ padding: "0" }}
                                    >
                                        <CompanyData
                                            companyData={companyData}
                                            salesMeta={salesMeta}
                                        />
                                    </PanelSection>
                                )}

                                {!!rewardOrdersData.length && (
                                    <PanelSection
                                        heading="Reward Orders"
                                        style={{ padding: "0" }}
                                    >
                                        <CompanyRewardOrders
                                            handlePageChange={
                                                handleRewardOrdersPageChange
                                            }
                                            handlePerPage={
                                                handleRewardOrdersPerPage
                                            }
                                            perPageValue={
                                                rewardOrdersPerPageValue
                                            }
                                            selectedPageValue={
                                                selectedRewardOrdersPageValue
                                            }
                                            rewardOrders={rewardOrdersData}
                                            meta={rewardOrdersMeta}
                                        />
                                    </PanelSection>
                                )}

                                {!!pointsData.length && (
                                    <PanelSection
                                        heading="Points Transactions"
                                        style={{ padding: "0" }}
                                    >
                                        <CompanyPoints
                                            perPageValue={pointsPerPageValue}
                                            selectedPageValue={
                                                selectedPointsPageValue
                                            }
                                            handlePageChange={
                                                handlePointsPageChange
                                            }
                                            handlePerPage={handlePointsPerPage}
                                            pointsData={pointsData}
                                            meta={pointsMeta}
                                        />
                                    </PanelSection>
                                )}

                                <PanelSection
                                    heading="Contacts"
                                    style={{ padding: "0" }}
                                >
                                    <CompanyContacts
                                        tradeTeamId={companyData.trade_team_id}
                                        handlePageChange={
                                            handleContactsPageChange
                                        }
                                        handlePerPage={handleContactsPerPage}
                                        perPageValue={contactsPerPageValue}
                                        selectedPageValue={
                                            selectedContactsPageValue
                                        }
                                        contacts={contactsData}
                                        meta={contactsMeta}
                                    />
                                </PanelSection>

                                {!!linkedAccountsData.length && (
                                    <PanelSection
                                        heading="Linked Accounts"
                                        style={{ padding: "0" }}
                                    >
                                        <CompanyLinkedAccounts
                                            handlePageChange={
                                                handleLinkedAccountsPageChange
                                            }
                                            handlePerPage={
                                                handleLinkedAccountsPerPage
                                            }
                                            perPageValue={
                                                linkedAccountsPerPageValue
                                            }
                                            selectedPageValue={
                                                selectedLinkedAccountsPageValue
                                            }
                                            linkedAccounts={linkedAccountsData}
                                            company={companyData}
                                            refreshCompany={getLinkedAccounts}
                                            meta={linkedAccountsMeta}
                                        />
                                    </PanelSection>
                                )}

                                {!!communicationData.length && (
                                    <PanelSection
                                        style={{ padding: "0" }}
                                        heading="Communications"
                                    >
                                        <CompanyCommunications
                                            handlePageChange={
                                                handleCommunicationsPageChange
                                            }
                                            handlePerPage={
                                                handleContactsPerPage
                                            }
                                            perPageValue={
                                                communicationsPerPageValue
                                            }
                                            selectedPageValue={
                                                selectedCommunicationsPageValue
                                            }
                                            communications={communicationData}
                                            meta={communicationMeta}
                                        />
                                    </PanelSection>
                                )}

                                {!!salesData.length && (
                                    <PanelSection
                                        style={{ padding: "0" }}
                                        heading="Sales"
                                    >
                                        <CompanySales
                                            handlePageChange={
                                                handleSalesPageChange
                                            }
                                            handlePerPage={handleSalesPerPage}
                                            handleSortChange={
                                                handleSalesSortChange
                                            }
                                            perPageValue={salesPerPageValue}
                                            selectedPageValue={
                                                selectedSalesPageValue
                                            }
                                            orderByValue={salesOrderByValue}
                                            orderDirectionValue={
                                                salesOrderDirectionValue
                                            }
                                            sales={salesData}
                                            meta={salesMeta}
                                        />
                                    </PanelSection>
                                )}

                                {!!mergeLogData.length && (
                                    <PanelSection
                                        heading="Merge Log"
                                        style={{ padding: "0" }}
                                    >
                                        <CompanyMergeLog
                                            tradeTeamId={
                                                companyData.trade_team_id
                                            }
                                            handlePageChange={
                                                handleContactsPageChange
                                            }
                                            handlePerPage={mergeLogPerPage}
                                            perPageValue={mergeLogPerPageValue}
                                            selectedPageValue={
                                                selectedMergeLogPageValue
                                            }
                                            mergeLog={mergeLogData}
                                            meta={mergeLogDataMeta}
                                        />
                                    </PanelSection>
                                )}
                            </Tabordion>
                        </Panel>
                    </CompanyActions>
                </CompanyContainer>
            )}
        </>
    );
};

export default ViewCompany;
